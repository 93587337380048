import { default as indexif8xBg25EOMeta } from "/opt/build/repo/pages/about/index.vue?macro=true";
import { default as cartl5ckCViQWgMeta } from "/opt/build/repo/pages/cart.vue?macro=true";
import { default as _91handle_93GXJ18RGxSPMeta } from "/opt/build/repo/pages/collections/[handle].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as mtr2nIgflywo6Meta } from "/opt/build/repo/pages/mtr.vue?macro=true";
import { default as _91pageHandle_934wvPhwrAHvMeta } from "/opt/build/repo/pages/pages/[pageHandle].vue?macro=true";
import { default as _91handle_93Jhfzip6ZHSMeta } from "/opt/build/repo/pages/products/[handle].vue?macro=true";
import { default as testsQ0rJvZrjqMeta } from "/opt/build/repo/pages/test.vue?macro=true";
export default [
  {
    name: indexif8xBg25EOMeta?.name ?? "about",
    path: indexif8xBg25EOMeta?.path ?? "/about",
    meta: indexif8xBg25EOMeta || {},
    alias: indexif8xBg25EOMeta?.alias || [],
    redirect: indexif8xBg25EOMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: cartl5ckCViQWgMeta?.name ?? "cart",
    path: cartl5ckCViQWgMeta?.path ?? "/cart",
    meta: cartl5ckCViQWgMeta || {},
    alias: cartl5ckCViQWgMeta?.alias || [],
    redirect: cartl5ckCViQWgMeta?.redirect,
    component: () => import("/opt/build/repo/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: _91handle_93GXJ18RGxSPMeta?.name ?? "collections-handle",
    path: _91handle_93GXJ18RGxSPMeta?.path ?? "/collections/:handle()",
    meta: _91handle_93GXJ18RGxSPMeta || {},
    alias: _91handle_93GXJ18RGxSPMeta?.alias || [],
    redirect: _91handle_93GXJ18RGxSPMeta?.redirect,
    component: () => import("/opt/build/repo/pages/collections/[handle].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mtr2nIgflywo6Meta?.name ?? "mtr",
    path: mtr2nIgflywo6Meta?.path ?? "/mtr",
    meta: mtr2nIgflywo6Meta || {},
    alias: mtr2nIgflywo6Meta?.alias || [],
    redirect: mtr2nIgflywo6Meta?.redirect,
    component: () => import("/opt/build/repo/pages/mtr.vue").then(m => m.default || m)
  },
  {
    name: _91pageHandle_934wvPhwrAHvMeta?.name ?? "pages-pageHandle",
    path: _91pageHandle_934wvPhwrAHvMeta?.path ?? "/pages/:pageHandle()",
    meta: _91pageHandle_934wvPhwrAHvMeta || {},
    alias: _91pageHandle_934wvPhwrAHvMeta?.alias || [],
    redirect: _91pageHandle_934wvPhwrAHvMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pages/[pageHandle].vue").then(m => m.default || m)
  },
  {
    name: _91handle_93Jhfzip6ZHSMeta?.name ?? "products-handle",
    path: _91handle_93Jhfzip6ZHSMeta?.path ?? "/products/:handle()",
    meta: _91handle_93Jhfzip6ZHSMeta || {},
    alias: _91handle_93Jhfzip6ZHSMeta?.alias || [],
    redirect: _91handle_93Jhfzip6ZHSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/products/[handle].vue").then(m => m.default || m)
  },
  {
    name: testsQ0rJvZrjqMeta?.name ?? "test",
    path: testsQ0rJvZrjqMeta?.path ?? "/test",
    meta: testsQ0rJvZrjqMeta || {},
    alias: testsQ0rJvZrjqMeta?.alias || [],
    redirect: testsQ0rJvZrjqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/test.vue").then(m => m.default || m)
  }
]